'use client'

import React from 'react'
import { ThemeProvider } from 'next-themes'

import { ConversationProvider } from '@/app/context/ConversationContext'
import { Toaster } from './components/ui/toaster'

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <ThemeProvider
      attribute="data-theme"
      defaultTheme="system"
      enableSystem
      disableTransitionOnChange
      themeColor={{
        light: 'hsl(0 0% 100%)',
        dark: 'hsl(216 13% 15%)'
      }}
    >
      <ConversationProvider>
        {children}
        <div id="toaster">
          <Toaster />
        </div>
      </ConversationProvider>
    </ThemeProvider>
  )
}
